<template>
  <div class="pos-rel">
    <div class="fixed">
      <!-- <div class="flex pos-rel bgc-white border-b">
        <div class="iconfont" @click="back">
          <i class="iconfont icon-zuojiantou"></i>
        </div>
        <div class="city">城市列表</div>
      </div> -->
      <div class="flex jcc ipt">
        <van-search v-model="value" placeholder="请输入搜索关键词" />
      </div>
    </div>

    <!-- 城市列表 -->

    <div v-if="!value" class="mt">
      <div>
        <div class="p-10 font-s-14">当前城市</div>
        <div class="bgc-white p-10">
          <van-button type="default" size="small" class="now" @click="nowAdress(now)">
            {{ now }}</van-button>
        </div>

        <div class="p-10 font-s-14">热门城市</div>
        <div class="bgc-white flex flex-wrap jcsb p-lr-15">
          <van-button class="now hot" v-for="(item, index) in this.citys.data.hotCities" :key="index" type="default"
            size="small" @click="switchAdress(item)">{{ item.name }}</van-button>
        </div>
      </div>


      <van-index-bar highlight-color="rgb(212, 152, 39)" :index-list="pys">
        <div v-for="(keys, index) in citysPY" :key="index">
          <van-index-anchor :index="keys.type" />
          <van-cell v-for="(item1, index1) in citysPY[index].list" :key="index1" :title="item1.provinceName"
            @click="switchAdress2(item1)" />
        </div>
      </van-index-bar>
    </div>

    <!-- 搜索时出现 -->
    <div v-else class="bgc-white p-10 mt">
      <div v-for="item in seach" class="p-10" @click="switchAdress2(item)">
        {{ item.provinceName }}
      </div>
    </div>
  </div>
</template>

<script>
import HeadChild from "../../components/HeadChild";
import city from "../../lip/city";
import pinyin from 'js-pinyin'


export default {
  name: "SelectAdderss",
  props: {},
  data() {
    return {
      citys: city,
      citysPY: null,
      pys: ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'J', 'K', 'L', 'M', 'N', 'P', 'Q', 'R', 'S', 'T', 'W', 'X', 'Y', 'Z'],
      value: null,
      now: null,
      cityList: [],
      type: 0
    };
  },
  components: { HeadChild, pinyin },
  methods: {
    //   返回
    back() {
      this.$router.back();
    },
    // 切换地址
    switchAdress(e) {
      this.cityList.forEach(element => {
        if (element.provinceName.includes(e.name)) {
          localStorage.setItem("address", element.provinceName.replace("市", ""));
          localStorage.setItem("cityCode", element.provinceId);
          this.$store.commit("setAddress", element.provinceName.replace("市", ""));
          this.back()
        }
      });
    },
    // 切换地址
    switchAdress2(e) {
      localStorage.setItem("address", e.provinceName.replace("市", ""));
      localStorage.setItem("cityCode", e.provinceId);
      this.$store.commit("setAddress", e.provinceName.replace("市", ""));
      this.back()

    },

    // 切换地址
    nowAdress(e) {

      // 本地储存选着的地址，避免刷新后变化
      localStorage.setItem("address", e);
      this.$store.commit("setAddress", e);
      this.back()
    },
    getCityList() {
      this.$api
        .queryCityList('')
        .then((res) => {
          if (res.data && res.data.length > 0) {
            this.cityList = res.data
            for (let i = 0; i < this.cityList.length; i++) {
              this.cityList[i].pinyin = pinyin.getFullChars(this.cityList[i].provinceName)
              if (this.cityList[i].pinyin.includes('ZhongQing')) {
                this.cityList[i].pinyin = 'ChongQingShi'
              }
            }
            this.filterCity(this.cityList)
          }
        })
        .catch((err) => {
          this.$Toast.fail("此卡不可用");
        });
    },
    filterCity(list) {
      let letterArray = []; // 定义一个数组来接受A、B、C、D这些type值
      for (let i = 65; i < 91; i++) {
        letterArray.push(String.fromCharCode(i));
        // 使用String.fromCharCode将ASCII码值转化为对应的大写字母
      }
      let newCities = [] // 定义一个数组来接收我们每个字母对应的数据
      letterArray.forEach(item => {
        newCities.push({
          type: `${item}`,
          list: list.filter(item1 => item1.pinyin.substring(0, 1).toUpperCase() === `${item}`)
        })
      })
      newCities = newCities.filter(item => item.list.length)
      // 过滤掉空数据
      this.citysPY = newCities
    }

  },
  mounted() {
    this.now = this.$store.state.address;
    // this.cityList=getCityList()
    this.getCityList()
    this.type = this.$route.query.type
    // console.log(Object.values(this.citys.data.cities));
  },
  computed: {
    seach() {
      let address = [];
      this.cityList.map((a) => {
        if (a.provinceName.includes(this.value) || a.pinyin.includes(this.value)) {
          address.push(a);
        }
      });
      // 判断包含地址是不是空
      address.length > 0 ? address : (address = ["无该地址"]);
      return address;
    },
  },

  watch: {
  },
};
</script>

<style lang='scss' scoped>
.fixed {
  width: 100%;
  background-color: #fff;

}

.mt {
  // margin-top: 28%;
}

.iconfont {
  text-align: left !important;
  padding: 10px 5px;
  font-size: 20px !important;
  margin-left: 0;
}

.city {
  font-size: 18px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.ipt {
  padding: 10px;

  .van-search {
    width: 90%;
    padding: 0;
    border-radius: 10px;
  }
}

.hot {
  margin: 10px 5px;
}

.now {
  width: 26%;
}
</style>